var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-space-between mb-0 py-7"},[_c('span',{staticClass:"text-h5"},[_c('strong',[_vm._v("Adverse Reaction Report")])])]),_c('v-row',{staticClass:"ml-2 mr-2 mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Tissue ID","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.tissue_id),callback:function ($$v) {_vm.$set(_vm.filter, "tissue_id", $$v)},expression:"filter.tissue_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Surgeon Name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.surgeon_name),callback:function ($$v) {_vm.$set(_vm.filter, "surgeon_name", $$v)},expression:"filter.surgeon_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Surgeon Location","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.surgery_location),callback:function ($$v) {_vm.$set(_vm.filter, "surgery_location", $$v)},expression:"filter.surgery_location"}})],1),_c('v-col',{staticClass:"pl-0 text-right",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.AllSearch()}}},[_vm._v(" Search")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.RecipientView()}}},[_vm._v("Reset")])],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.AllData,"items-per-page":10,"fixed-header":"","height":"400","item-key":"donor_id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.date_of_diagnosis",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(_vm.moment.utc(item.date_of_diagnosis).format("DD-MM-YYYY HH:mm")))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.pdf_path != '')?_c('v-btn',{attrs:{"color":"primary","href":("https://api.ebsr.in/" + (item.pdf_path)),"target":"_blank"}},[_vm._v(" Print ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pt-3 col-12"},[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('p',[_c('strong',[_vm._v(" Tissue Source Information: ")])]),_c('p',[_vm._v("Tissue Obtained From: "+_vm._s(item.tissue_obtained_from))]),_c('p',[_vm._v("Tissue Distributed: "+_vm._s(item.tissue_distribution))]),_c('p',[_vm._v("Recipient ID: "+_vm._s(item.recipient_id))])]),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('p',[_c('strong',[_vm._v("Recipient Information")])]),_c('p',[_vm._v("Patient Name: "+_vm._s(item.patient_name))]),_c('p',[_vm._v("Patient Age: "+_vm._s(item.patient_age))]),_c('p',[_vm._v("Patient ID: "+_vm._s(item.patient_id))]),_c('p',[_vm._v("Gender: "+_vm._s(item.gender))])])],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }