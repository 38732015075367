<template>
  <v-card>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong>Adverse Reaction Report</strong></span>
    </v-card-title> -->
    <v-card-title class="justify-space-between mb-0 py-7">
            <span class="text-h5"><strong>Adverse Reaction Report</strong></span>
        </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field label="Tissue ID" v-model="filter.tissue_id" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.surgeon_name" label="Surgeon Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
         <v-text-field v-model="filter.surgery_location" label="Surgeon Location" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3"  class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="AllSearch()"> Search</v-btn>
        <v-btn color="primary"  @click="RecipientView()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="2">
   
      </v-col> -->
    </v-row>

    <v-data-table
      :headers="headers"
      :items="AllData"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
      item-key="donor_id"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template #[`item.date_of_diagnosis`]="{ item }">
        <pre>{{moment.utc(item.date_of_diagnosis).format("DD-MM-YYYY HH:mm")}}</pre>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn color="primary" v-if="item.pdf_path != ''" :href="`https://api.ebsr.in/${item.pdf_path}`" target="_blank"> 
          Print 
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="pt-3 col-12">
          <v-col sm="6" md="6">
            <p><strong> Tissue Source Information: </strong></p>
          <p>Tissue Obtained From: {{ item.tissue_obtained_from }}</p>
          <p>Tissue Distributed: {{ item.tissue_distribution }}</p>
          <p>Recipient ID: {{ item.recipient_id }}</p>
          </v-col>
          <v-col  sm="6" md="6">
            <p><strong>Recipient Information</strong></p>
          <p >Patient Name: {{ item.patient_name }}</p>
          <p >Patient Age: {{ item.patient_age }}</p>
          <p >Patient ID:  {{ item.patient_id }}</p>
          <p >Gender:  {{ item.gender }}</p>
        </v-col>
        </v-row>
      </td>
    </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>


import { api } from '@/config/config'
import donorService from '@/service/Donor.service'
import allservice from '@/service/AdverseReaction.service'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      expanded:[],
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      permissionData: [],
      AllData: [],
      filter: {
        tissue_id: '',
        surgeon_name: '',
        surgery_location: '',
      },

      headers: [
        { text: 'Tissue ID', value: 'tissue_id', width: '150px' },
        { text: 'Surgeon Name', value: 'surgeon_name', width: '150px' },
        { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
        { text: 'Donor Age', value: 'donor_age', width: '150px' },
        { text: 'Date-Time of Diagnose', value: 'date_of_diagnosis', width: '150px' },
         { text: 'Action', value: 'action', width: '150px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.RecipientView()
      },
      deep: true,
    },
  },

  async mounted() {
    this.permission()
    this.RecipientView()
  },

  methods: {

    async permission() {
      const service = new donorService()
      let response = await service.donorList()
      this.permissionData = response.permissions
    },

    async RecipientView() {
      this.listLoading = true
      const service = new allservice()
      let response = await service.AllList()
      if (response) {
        this.AllData = response
        // console.log("Hello Bill2",response.data)
        this.totallist = this.AllData.length
        ;(this.filter.tissue_id = ''),
          (this.filter.surgeon_name = ''),
          (this.filter.surgery_location = '')
      } else {
        this.AllData = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async AllSearch() {
      this.listLoading = true
      const data = {
        tissue_id: this.filter.tissue_id,
        surgeon_name: this.filter.surgeon_name,
        surgery_location: this.filter.surgery_location
      }

      if (this.filter.tissue_id == '' && this.filter.surgeon_name == '' && this.filter.surgery_location == '') {
        this.snackbarText = 'Kindly Enter The Keyword',
          this.snackbar = true
        return;
      }

      try {
        const response = await api.post(`adverseReaction/search_adverse_reaction`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          console.log("hello",response.data)
          this.AllData = response.data
          this.totallist = this.AllData.length
        } else {
          this.AllData = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
